import React, { useEffect, useState } from 'react'
import { Button, FormControl, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Form } from 'react-router-dom'
import { ScheduleRecuringTime } from '../../models/shared/Device'
import { getDateFromTimeString } from '../../utils/shared/calculations'

interface Props {
    show: boolean
    onClose: () => void
    scheduleTmes: ScheduleRecuringTime[],
    onSave: (times: ScheduleRecuringTime[]) => void
}

interface Inputs {
    time: string
}

function ScheduleLightModal(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()
    const [times, setTimes] = useState<ScheduleRecuringTime[]>(props.scheduleTmes)

    useEffect(() => {
        setTimes(props.scheduleTmes)
    }, [props.scheduleTmes])

    const onSubmit = (data: Inputs) => {
        const [hours, minutes] = data.time.split(':')
        const date = new Date()
        date.setHours(parseInt(hours))
        date.setMinutes(parseInt(minutes))
        const timeString = `${date.getUTCHours()}:${date.getUTCMinutes()}`
        const newTime = { timeString }

        const newTimes = [...times, newTime]

        setTimes(newTimes)
    }

    const deleteTime = (index: number) => {
        const newTimes = [...times]
        newTimes.splice(index, 1)
        setTimes(newTimes)
    }

    const handleSave = () => {
        props.onSave(times)
    }

    return <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <h6>New Lights Up</h6>
                <FormControl type='time' {...register('time', { required: true })} isInvalid={!!errors.time} />
                <Button type='submit' variant='outline-primary' className='mt-2'>Add</Button>
            </Form>

            <hr />
            <h6>Current Schedule</h6>

            <ul>
                {times.map((time, index) => <li key={index}>
                    {getDateFromTimeString(time.timeString).toLocaleTimeString()}
                    <Button className='ms-3' variant='outline-danger' size='sm' onClick={() => deleteTime(index)}>X</Button>
                </li>)}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='outline-secondary' onClick={props.onClose}>cancel</Button>
            <Button onClick={handleSave} variant='primary'>Save</Button>
        </Modal.Footer>
    </Modal>
}

export default ScheduleLightModal
